import '../scss/cookies.scss';
import { getCookie } from './utils/cookie'

/**
 * Initializes the cookies modal on page load and handles the user's consent action.
 * The modal is shown if the user hasn't previously accepted the cookies.
 */
document.addEventListener('DOMContentLoaded', () => {
    const modalElement = document.getElementById('cookies');
    const cookieName = "cookies";
    const cookieValue = getCookie(cookieName);

    if (modalElement && cookieValue !== 'no') {
        const cookiesButton = modalElement.querySelector('#cookies-button');
        show(modalElement);

        cookiesButton.addEventListener('click', () => {
            addCookie(cookieName);
            hide(modalElement);
        });
    }
});

/**
 * Displays the cookies modal by adjusting its CSS classes.
 *
 * @param {HTMLElement} modalElement - The modal element to be shown.
 */
function show(modalElement) {
    modalElement.classList.remove('d-none');
}

/**
 * Hides the cookies modal by adjusting its CSS classes.
 *
 * @param {HTMLElement} modalElement - The modal element to be hidden.
 */
function hide(modalElement) {
    modalElement.classList.add('d-none');
}

/**
 * Adds a cookie to the document with an expiration date of six months.
 *
 * @param {string} cookieName - The name of the cookie to be set.
 */
function addCookie(cookieName) {
    const SIX_MONTHS_IN_MILLISECONDS = 15780000000;
    const now = new Date();
    const time = now.getTime();

    const expireTime = time + SIX_MONTHS_IN_MILLISECONDS;
    now.setTime(expireTime);

    document.cookie = `${cookieName}=no;expires=${now.toUTCString()};path=/`;
}